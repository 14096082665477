<template>
  <main data-aos="fade-up" data-aos-duration="1000">
    <!-- hero-area start -->
    <section class="breadcrumb-bg pt-100 pb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-9">
            <div class="page-title">
              <h1>{{news_data.title}}</h1>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
            <div class="page-breadcumb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb ">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">News</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- hero-area end -->

    <!-- blog-area start -->
    <section class="blog-area pt-120 pb-80">
      <div class="container">
        <div class="row mobile-version">
          <div class="col-lg-4">
            <newsLeftBar />
          </div>
          <div class="col-lg-8">
            <div class="loading" v-if="loading">
              <img src="../assets/loading-animacion.gif" alt="">
            </div>
            <article v-else class="postbox post format-image mb-40">
              <div class="postbox__thumb mb-35 default-img">
                <img :src="news_data.image['data']['full_url']" alt="blog image">
              </div>
              <div class="postbox__text bg-none">
                <div class="post-meta mb-15">
                  <span><i class="far fa-calendar-check"></i> {{moment(news_data.date)}} </span>
                </div>
                <div class="post-text mb-20">
                  <p v-html="news_data.text"></p>
                </div>
                <div class="row mt-50">
                  <div class="col-12">
                    <div class="blog-share-icon text-right text-md-right">
                      <span>Share: </span>
                      <ShareNetwork
                          network="facebook"
                          :url="news_data.href"
                          :title="news_data.title"
                          :description="news_data.description"
                      >
                        <i class="fab fa-facebook-f"></i>
                      </ShareNetwork>
                      <ShareNetwork
                          network="twitter"
                          :url="news_data.href"
                          :title="news_data.title"
                          :description="news_data.description"
                      >
                        <i class="fab fa-twitter"></i>
                      </ShareNetwork>
                      <ShareNetwork
                          network="linkedin"
                          :url="news_data.href"
                          :title="news_data.title"
                          :description="news_data.description"
                      >
                        <i class="fab fa-linkedin"></i>
                      </ShareNetwork>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
    <!-- blog-area end -->
  </main>
</template>

<script>
    import newsLeftBar from "../components/news-left-bar"
    import api from "../services/api";
    import moment from "moment";
    export default {
        name: "news-details",
        data() {
            return {
                news_data: [],
                loading: true
            }
        },
        components: {
            newsLeftBar
        },
        methods: {
            async init () {
                if (this.$route.params.url) {
                    this.loading = true;
                    try {
                        this.news_data = await api.newsDetail(this.$route.params.url);
                        this.news_data = this.news_data[0];
                        this.news_data.href = window.location.href;
                        this.loading = false;
                    } catch {
                        this.news_data = [];
                        this.loading = false;
                    }
                } else {
                    this.$router.push({ name: '/' });
                }
            },
            moment (date) {
                return moment(date).format('MMMM D, YYYY');
            },
        },
        watch: {
            '$route.params': {
                immediate: true,
                handler() {
                    window.scrollTo(0,0);
                    this.init();
                }
            }
        },
    }
</script>

<style scoped>
  .breadcrumb-bg {
    background-image: url("../assets/img/blog/details/news-banner.png");
    position: relative;
  }
  .breadcrumb-bg::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.72);
  }
  .breadcrumb-bg .container {
    position: relative;
    z-index: 2;
  }
  .loading {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .default-img {
    height: 440px;
  }
  .default-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .blog-share-icon a {
    cursor: pointer;
  }
</style>
